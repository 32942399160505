import {
  changeDrawerLayout,
  changeIsDrawerOpen,
  toggleIsGridCompact,
  useAppDispatch,
  useAppSelector,
} from '@graphstax/redux';
import cn from 'classnames';
import { motion } from 'framer-motion';
import Button from '../general/button';
import { CompactGridIcon } from '../icons/compact-grid.component';
import { NormalGridIcon } from '../icons/normal-grid.component';
import { OptionIcon } from '../icons/option.component';
import Feeds, { ExperimentEntryType } from './feeds.component';
import { SortList } from './filters.component';

function GridSwitcher() {
  const dispatch = useAppDispatch();
  const { isDrawerOpen, isGridCompact } = useAppSelector(
    (state) => state.layout
  );

  return (
    <div className="flex overflow-hidden rounded-lg">
      <button
        className={cn(
          'relative flex h-11 w-11 items-center justify-center bg-gray-100 transition dark:bg-gray-800',
          {
            'z-10 text-white': !isGridCompact,
            'text-brand dark:text-white': isGridCompact,
          }
        )}
        onClick={() => dispatch(toggleIsGridCompact())}
        aria-label="Normal Grid"
      >
        {!isGridCompact && (
          <motion.span
            className="absolute left-0 right-0 bottom-0 h-full w-full bg-brand shadow-large"
            layoutId="gridSwitchIndicator"
          />
        )}
        <NormalGridIcon className="relative" />
      </button>
      <button
        className={cn(
          'relative flex h-11 w-11 items-center justify-center bg-gray-100 transition dark:bg-gray-800',
          {
            'z-10 text-white': isGridCompact,
            'text-brand dark:text-white': !isGridCompact,
          }
        )}
        onClick={() => dispatch(toggleIsGridCompact())}
        aria-label="Normal Grid"
      >
        {isGridCompact && (
          <motion.span
            className="absolute left-0 right-0 bottom-0 h-full w-full  bg-brand shadow-large"
            layoutId="gridSwitchIndicator"
          />
        )}
        <CompactGridIcon className="relative" />
      </button>
      <button
        className={cn(
          'relative flex h-11 w-11 items-center justify-center bg-gray-100 transition dark:bg-gray-800',
          {
            'z-10 text-white': isDrawerOpen,
            'text-brand dark:text-white': !isDrawerOpen,
          }
        )}
        onClick={() => {
          dispatch(changeDrawerLayout('DRAWER_SEARCH'));
          dispatch(changeIsDrawerOpen(true));
        }}
        aria-label="Filter"
      >
        {isDrawerOpen && (
          <motion.span
            className="absolute left-0 right-0 bottom-0 h-full w-full  bg-brand shadow-large"
            layoutId="gridSwitchIndicator"
          />
        )}
        <OptionIcon className="relative h-auto w-5" />
      </button>
    </div>
  );
}

export default function RetroSearch({
  experiments,
}: {
  experiments: ExperimentEntryType[];
}) {
  const dispatch = useAppDispatch();
  return (
    <div className="grid sm:pt-5">
      <div className="relative z-10 mb-6 flex items-center justify-between">
        <span className="text-xs font-medium text-gray-900 dark:text-white sm:text-sm">
          5,686,066 items
        </span>
        <div className="flex gap-6 3xl:gap-8">
          <SortList />
          <div className="hidden 3xl:block">
            <GridSwitcher />
          </div>
          <div className="hidden sm:flex sm:items-center sm:justify-center 3xl:hidden">
            <button
              type="button"
              onClick={() => {
                dispatch(changeDrawerLayout('DRAWER_SEARCH'));
                dispatch(changeIsDrawerOpen(true));
              }}
              className="hover:opacity-90 focus:opacity-90"
            >
              <OptionIcon className="relative h-auto w-4" />
            </button>
          </div>
        </div>
      </div>
      <Feeds className="md:!grid-cols-2 lg:!grid-cols-3" {...{ experiments }} />
      <div className="fixed bottom-6 left-1/2 z-10 w-full -translate-x-1/2 px-9 sm:hidden">
        <Button
          onClick={() => {
            dispatch(changeDrawerLayout('DRAWER_SEARCH'));
            dispatch(changeIsDrawerOpen(true));
          }}
          fullWidth
        >
          Filters
        </Button>
      </div>
    </div>
  );
}
