import Image from 'next/image';

export const coinList = [
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    code: 'BTC',
    name: 'Bitcoin',
    price: 19076.29,
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    code: 'ETH',
    name: 'Ethereum',
    price: 1053.28,
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    code: 'USDT',
    name: 'Tether USD',
    price: 0.999,
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    code: 'BNB',
    name: 'Binance Coin',
    price: 214.96,
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    code: 'USDC',
    name: 'USD Coin',
    price: 1.001,
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    code: 'ADA',
    name: 'Cardano',
    price: 0.448,
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    code: 'DOGE',
    name: 'Doge Coin',
    price: 0.065,
  },
];
