import { v4 as uuidv4 } from 'uuid';

export const cripticVotes = [
  {
    id: '1',
    title: 'PTIP 50 - Treasury Assets Management #1',
    accepted: {
      vote: 10303,
      percentage: 90,
    },
    rejected: {
      vote: 303,
      percentage: 10,
    },
    executed_at: '2022-10-01T01:02:03',
    proposed_by: {
      id: '0x9aba...0bd8',
      link: '#',
    },
    status: 'active',
    votes: [
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 32125,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 26081,
        status: 'rejected',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 2125,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 6081,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 181,
        status: 'rejected',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 32125,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 26081,
        status: 'rejected',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 2125,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 6081,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 181,
        status: 'rejected',
      },
    ],
    description: `
      <h6>Simple Summary</h6>
      <p>Extend POOL drip for UNI, COMP, Dai, USDC, and SUSHI Ethereum pools until the end of October. Extend prizes and drip for the POOL pool as well.</p>
      <h6>Motivation</h6>
      <p>The POOL drips for most Ethereum pools will be ending within the next 2 weeks (see details here). This proposal serves to maintain the statusquo of POOL incentives on Ethereum. However, it only extends the status quo for a relatively short amount of additional time. This is becausethe V4 prize pool will be launching in mid October and a larger incentive overhaul will be.</p>
      <h6>Where Can I get Some?</h6>
      <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
    `,
    action: [
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
    ],
  },
  {
    id: '2',
    title: 'PTIP 51 - Retroactive Proxy Contract Airdrop — Phase One',
    accepted: {
      vote: 303,
      percentage: 30,
    },
    rejected: {
      vote: 787,
      percentage: 70,
    },
    executed_at: '2021-02-01T01:02:03',
    proposed_by: {
      id: '0x9aba...0bd8',
      link: '#',
    },
    status: 'past',
    votes: [
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 32125,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 26081,
        status: 'rejected',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 2125,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 6081,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 181,
        status: 'rejected',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 32125,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 26081,
        status: 'rejected',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 2125,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 6081,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 181,
        status: 'rejected',
      },
    ],
    description: `
      <h6>Simple Summary</h6>
      <p>Extend POOL drip for UNI, COMP, Dai, USDC, and SUSHI Ethereum pools until the end of October. Extend prizes and drip for the POOL pool as well.</p>
      <h6>Motivation</h6>
      <p>The POOL drips for most Ethereum pools will be ending within the next 2 weeks (see details here). This proposal serves to maintain the statusquo of POOL incentives on Ethereum. However, it only extends the status quo for a relatively short amount of additional time. This is becausethe V4 prize pool will be launching in mid October and a larger incentive overhaul will be.</p>
      <h6>Where Can I get Some?</h6>
      <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
    `,
    action: [
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
    ],
  },
  {
    id: '3',
    title: 'PTIP 52 - Reduce the UNI proposal submission threshold to 2.5M',
    accepted: {
      vote: 7303,
      percentage: 87,
    },
    rejected: {
      vote: 813,
      percentage: 23,
    },
    status: 'past',
    executed_at: '2021-07-01T01:02:03',
    proposed_by: {
      id: '0x9aba...0bd8',
      link: '#',
    },
    votes: [
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 32125,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 26081,
        status: 'rejected',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 2125,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 6081,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 181,
        status: 'rejected',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 32125,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 26081,
        status: 'rejected',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 2125,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 6081,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 181,
        status: 'rejected',
      },
    ],
    description: `
      <h6>Simple Summary</h6>
      <p>Extend POOL drip for UNI, COMP, Dai, USDC, and SUSHI Ethereum pools until the end of October. Extend prizes and drip for the POOL pool as well.</p>
      <h6>Motivation</h6>
      <p>The POOL drips for most Ethereum pools will be ending within the next 2 weeks (see details here). This proposal serves to maintain the statusquo of POOL incentives on Ethereum. However, it only extends the status quo for a relatively short amount of additional time. This is becausethe V4 prize pool will be launching in mid October and a larger incentive overhaul will be.</p>
      <h6>Where Can I get Some?</h6>
      <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
    `,
    action: [
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
    ],
  },
  {
    id: '4',
    title: 'PTIP 53 - Community-Enabled Analytics',
    accepted: {
      vote: 9303,
      percentage: 90,
    },
    rejected: {
      vote: 989,
      percentage: 10,
    },
    status: 'past',
    executed_at: '2021-03-01T01:02:03',
    proposed_by: {
      id: '0x9aba...0bd8',
      link: '#',
    },
    votes: [
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 32125,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 26081,
        status: 'rejected',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 2125,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 6081,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 181,
        status: 'rejected',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 32125,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 26081,
        status: 'rejected',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 2125,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 6081,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 181,
        status: 'rejected',
      },
    ],
    description: `
      <h6>Simple Summary</h6>
      <p>Extend POOL drip for UNI, COMP, Dai, USDC, and SUSHI Ethereum pools until the end of October. Extend prizes and drip for the POOL pool as well.</p>
      <h6>Motivation</h6>
      <p>The POOL drips for most Ethereum pools will be ending within the next 2 weeks (see details here). This proposal serves to maintain the statusquo of POOL incentives on Ethereum. However, it only extends the status quo for a relatively short amount of additional time. This is becausethe V4 prize pool will be launching in mid October and a larger incentive overhaul will be.</p>
      <h6>Where Can I get Some?</h6>
      <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
    `,
    action: [
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
    ],
  },
  {
    id: '5',
    title: 'PTIP 61 - Stake XRP Earn Cake Syrup Pool',
    accepted: {
      vote: 10303,
      percentage: 90,
    },
    rejected: {
      vote: 303,
      percentage: 10,
    },
    executed_at: '2022-11-01T01:01:11',
    proposed_by: {
      id: '0x9aba...0bd8',
      link: '#',
    },
    status: 'active',
    votes: [
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 32125,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 26081,
        status: 'rejected',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 2125,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 6081,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 181,
        status: 'rejected',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 32125,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 26081,
        status: 'rejected',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 2125,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 6081,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 181,
        status: 'rejected',
      },
    ],
    description: `
      <h6>Simple Summary</h6>
      <p>Extend POOL drip for UNI, COMP, Dai, USDC, and SUSHI Ethereum pools until the end of October. Extend prizes and drip for the POOL pool as well.</p>
      <h6>Motivation</h6>
      <p>The POOL drips for most Ethereum pools will be ending within the next 2 weeks (see details here). This proposal serves to maintain the statusquo of POOL incentives on Ethereum. However, it only extends the status quo for a relatively short amount of additional time. This is becausethe V4 prize pool will be launching in mid October and a larger incentive overhaul will be.</p>
      <h6>Where Can I get Some?</h6>
      <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
    `,
    action: [
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
    ],
  },
  {
    id: '6',
    title: 'PTIP 63 - Claim Rewards from Locked Staking',
    accepted: {
      vote: 1003,
      percentage: 90,
    },
    rejected: {
      vote: 303,
      percentage: 10,
    },
    executed_at: '2022-11-05T01:06:15',
    proposed_by: {
      id: '0x9aba...0bd8',
      link: '#',
    },
    status: 'active',
    votes: [
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 32125,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 26081,
        status: 'rejected',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 2125,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 6081,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 181,
        status: 'rejected',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 32125,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 26081,
        status: 'rejected',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 2125,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 6081,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 181,
        status: 'rejected',
      },
    ],
    description: `
      <h6>Simple Summary</h6>
      <p>Extend POOL drip for UNI, COMP, Dai, USDC, and SUSHI Ethereum pools until the end of October. Extend prizes and drip for the POOL pool as well.</p>
      <h6>Motivation</h6>
      <p>The POOL drips for most Ethereum pools will be ending within the next 2 weeks (see details here). This proposal serves to maintain the statusquo of POOL incentives on Ethereum. However, it only extends the status quo for a relatively short amount of additional time. This is becausethe V4 prize pool will be launching in mid October and a larger incentive overhaul will be.</p>
      <h6>Where Can I get Some?</h6>
      <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
    `,
    action: [
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
    ],
  },
  {
    id: '7',
    title: 'PTIP 64 - Loan and/or fixed term with the Cake',
    accepted: {
      vote: 11003,
      percentage: 90,
    },
    rejected: {
      vote: 303,
      percentage: 10,
    },
    executed_at: '2022-12-08T01:04:04',
    proposed_by: {
      id: '0x9aba...0bd8',
      link: '#',
    },
    status: 'active',
    votes: [
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 32125,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 26081,
        status: 'rejected',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 2125,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 6081,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 181,
        status: 'rejected',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 32125,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 26081,
        status: 'rejected',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 2125,
        status: 'accepted',
      },
      {
        voter: { id: '0x9aba...0bd8', link: '#' },
        voting_weight: 6081,
        status: 'accepted',
      },
      {
        voter: { id: '0x956aba...0263bd8', link: '#' },
        voting_weight: 181,
        status: 'rejected',
      },
    ],
    description: `
      <h6>Simple Summary</h6>
      <p>Extend POOL drip for UNI, COMP, Dai, USDC, and SUSHI Ethereum pools until the end of October. Extend prizes and drip for the POOL pool as well.</p>
      <h6>Motivation</h6>
      <p>The POOL drips for most Ethereum pools will be ending within the next 2 weeks (see details here). This proposal serves to maintain the statusquo of POOL incentives on Ethereum. However, it only extends the status quo for a relatively short amount of additional time. This is becausethe V4 prize pool will be launching in mid October and a larger incentive overhaul will be.</p>
      <h6>Where Can I get Some?</h6>
      <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
    `,
    action: [
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
      {
        id: uuidv4(),
        contract: {
          id: '0x9aba...0bd8',
          link: '#',
        },
        method: 'transfer(address,uint256)',
        inputs:
          '0x000000000000000000000000bd537257fad96e977b9e545be583bbf7028f30b900000000000000000000000000000000000000000000065a4da25d3016c00000',
      },
    ],
  },
];

// getVotesByStatus
export function getVotesByStatus(status: string) {
  const votesByStatus = cripticVotes.filter((vote) => vote.status === status);
  return {
    votes: votesByStatus,
    totalVote: votesByStatus.length,
  };
}
