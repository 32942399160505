import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

import { persistor } from '../store';
import { IUserPayload, IUserState } from './types';

const initialState: IUserState = {
  name: undefined,
  occupation: undefined,
  profilePicture: undefined,
  loading: undefined,
  error: undefined,
};

export const resetUser = createAsyncThunk(
  '@@user/reset',
  async (_, { rejectWithValue }) => {
    try {
      // purge auth state from redux store
      await persistor.purge();
    } catch (e) {
      if (e instanceof Error) {
        rejectWithValue(e);
      }
    }
  },
);

const userSlice = createSlice({
  name: '@@user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserPayload>) => {
      state.name = action.payload.name;
      state.occupation = action.payload.occupation;
      state.profilePicture = action.payload.profilePicture;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(resetUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(resetUser.fulfilled, () => {
      // purge user state from redux store
    });
    builder.addCase(PURGE, (state) => {
      state.name = undefined;
      state.occupation = undefined;
      state.loading = undefined;
      state.error = undefined;
    });
  },
});

export const { setUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
