export function ArrowLinkIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3977 5.39999C11.2386 5.39999 11.086 5.46319 10.9735 5.57569C10.861 5.68819 10.7978 5.84077 10.7978 5.99987V10.7989H1.19975V1.20085H5.99877C6.15787 1.20085 6.31045 1.13765 6.42295 1.02515C6.53545 0.912654 6.59865 0.760073 6.59865 0.600976C6.59865 0.441878 6.53545 0.289297 6.42295 0.176799C6.31045 0.0642998 6.15787 0.00109863 5.99877 0.00109863H0.599877C0.44078 0.00109863 0.288199 0.0642998 0.1757 0.176799C0.0632011 0.289297 0 0.441878 0 0.600976V11.3988C0 11.5579 0.0632011 11.7104 0.1757 11.8229C0.288199 11.9354 0.44078 11.9986 0.599877 11.9986H11.3977C11.5568 11.9986 11.7093 11.9354 11.8218 11.8229C11.9343 11.7104 11.9975 11.5579 11.9975 11.3988V5.99987C11.9975 5.84077 11.9343 5.68819 11.8218 5.57569C11.7093 5.46319 11.5568 5.39999 11.3977 5.39999Z"
        fill="currentColor"
      />
      <path
        d="M11.6262 0.0484717C11.4264 -0.0343114 11.5428 0.0178779 8.39825 0.00108137C8.23915 0.00108137 8.08657 0.0642825 7.97407 0.176781C7.86157 0.28928 7.79837 0.441861 7.79837 0.600958C7.79837 0.760056 7.86157 0.912637 7.97407 1.02514C8.08657 1.13763 8.23915 1.20084 8.39825 1.20084H9.94953L5.57523 5.57514C5.51793 5.63048 5.47223 5.69667 5.44079 5.76986C5.40935 5.84304 5.3928 5.92176 5.39211 6.00141C5.39142 6.08106 5.4066 6.16005 5.43676 6.23378C5.46692 6.3075 5.51147 6.37448 5.56779 6.4308C5.62411 6.48712 5.69109 6.53167 5.76481 6.56183C5.83854 6.59199 5.91753 6.60717 5.99718 6.60648C6.07683 6.60578 6.15555 6.58924 6.22873 6.5578C6.30192 6.52636 6.36811 6.48066 6.42345 6.42336L10.7978 2.04966V3.60034C10.7978 3.75944 10.861 3.91202 10.9735 4.02452C11.086 4.13702 11.2385 4.20022 11.3976 4.20022C11.5567 4.20022 11.7093 4.13702 11.8218 4.02452C11.9343 3.91202 11.9975 3.75944 11.9975 3.60034C11.9819 0.440191 12.0341 0.570965 11.9513 0.372405C11.8902 0.225598 11.7732 0.109082 11.6262 0.0484717Z"
        fill="currentColor"
      />
    </svg>
  );
}
