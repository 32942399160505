export const VolumeData = [
  {
    dailyVolumeUSD: '333270564',
    date: 1622505600,
    totalLiquidityUSD: '3703090662',
    name: '02',
  },
  {
    dailyVolumeUSD: '411547973',
    date: 1622592000,
    totalLiquidityUSD: '4871908727',
    name: '03',
  },
  {
    dailyVolumeUSD: '582601964',
    date: 1622678400,
    totalLiquidityUSD: '5949219813',
    name: '04',
  },
  {
    dailyVolumeUSD: '678566822',
    date: 1622764800,
    totalLiquidityUSD: '3941875556',
    name: '05',
  },
  {
    dailyVolumeUSD: '702812550',
    date: 1622851200,
    totalLiquidityUSD: '3922395813',
    name: '06',
  },
  {
    dailyVolumeUSD: '530502539',
    date: 1622937600,
    totalLiquidityUSD: '3976251625',
    name: '07',
  },
  {
    dailyVolumeUSD: '762940634',
    date: 1623024000,
    totalLiquidityUSD: '3815788580',
    name: '08',
  },
  {
    dailyVolumeUSD: '888690463',
    date: 1623110400,
    totalLiquidityUSD: '3814132882',
    name: '09',
  },
  {
    dailyVolumeUSD: '949235732',
    date: 1623196800,
    totalLiquidityUSD: '4075538381',
    name: '10',
  },
  {
    dailyVolumeUSD: '721523311',
    date: 1623283200,
    totalLiquidityUSD: '4004433541',
    name: '11',
  },
  {
    dailyVolumeUSD: '621665245',
    date: 1623369600,
    totalLiquidityUSD: '4053222290',
    name: '12',
  },
  {
    dailyVolumeUSD: '696334595',
    date: 1623456000,
    totalLiquidityUSD: '4063003713',
    name: '13',
  },
  {
    dailyVolumeUSD: '722772374',
    date: 1623542400,
    totalLiquidityUSD: '4222396366',
    name: '14',
  },
  {
    dailyVolumeUSD: '698390916',
    date: 1623628800,
    totalLiquidityUSD: '4287632800',
    name: '15',
  },
  {
    dailyVolumeUSD: '558345299',
    date: 1623715200,
    totalLiquidityUSD: '4275282121',
    name: '16',
  },
  {
    dailyVolumeUSD: '589481920',
    date: 1623801600,
    totalLiquidityUSD: '4023020582',
    name: '17',
  },
  {
    dailyVolumeUSD: '548449241',
    date: 1623888000,
    totalLiquidityUSD: '4151293071',
    name: '18',
  },
  {
    dailyVolumeUSD: '547792029',
    date: 1623974400,
    totalLiquidityUSD: '4035278927',
    name: '19',
  },
  {
    dailyVolumeUSD: '510398073',
    date: 1624060800,
    totalLiquidityUSD: '4058540362',
    name: '20',
  },
  {
    dailyVolumeUSD: '679219596',
    date: 1624147200,
    totalLiquidityUSD: '4121875852',
    name: '21',
  },
];
