import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// TODO move run types to this folder
import type { ITaskOptionsAPIResponse } from './types';

export const tasksApi = createApi({
  reducerPath: 'tasksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/tasks',
  }),
  tagTypes: ['Tasks'],
  endpoints: (builder) => ({
    getTaskGenerators: builder.query<ITaskOptionsAPIResponse[], void>({
      query: () => `/generators`,
      providesTags: [{ type: 'Tasks', id: 'TASKS_GENERATORS' }],
    }),
    getTaskRewriters: builder.query<ITaskOptionsAPIResponse[], void>({
      query: () => `/rewriters`,
      providesTags: [{ type: 'Tasks', id: 'TASKS_REWRITERS' }],
    }),
    getTaskClassifiers: builder.query<ITaskOptionsAPIResponse[], void>({
      query: () => `/classifiers`,
      providesTags: [{ type: 'Tasks', id: 'TASKS_CLASSIFIERS' }],
    }),
    getTaskSplitters: builder.query<ITaskOptionsAPIResponse[], void>({
      query: () => `/splitters`,
      providesTags: [{ type: 'Tasks', id: 'TASKS_SPLITTERS' }],
    }),
    getTaskScorers: builder.query<ITaskOptionsAPIResponse[], void>({
      query: () => `/scorers`,
      providesTags: [{ type: 'Tasks', id: 'TASKS_SCORERS' }],
    }),
  }),
});

export const {
  useGetTaskGeneratorsQuery,
  useGetTaskRewritersQuery,
  useGetTaskScorersQuery,
  useGetTaskSplittersQuery,
  useGetTaskClassifiersQuery,
} = tasksApi;
