export function OptionIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <path
        d="M22 11C22 10.5254 21.6153 10.1406 21.1406 10.1406H19.4219C18.9472 10.1406 18.5625 10.5254 18.5625 11C18.5625 11.4746 18.9472 11.8594 19.4219 11.8594H21.1406C21.6153 11.8594 22 11.4746 22 11Z"
        fill="currentColor"
      />
      <path
        d="M0 11C0 11.4746 0.384743 11.8594 0.859375 11.8594H10.0775C10.4601 13.3403 11.8074 14.4375 13.4062 14.4375C15.3017 14.4375 16.8438 12.8954 16.8438 11C16.8438 9.10456 15.3017 7.5625 13.4062 7.5625C11.8074 7.5625 10.4601 8.65966 10.0775 10.1406H0.859375C0.384743 10.1406 0 10.5254 0 11ZM13.4062 9.28125C14.354 9.28125 15.125 10.0523 15.125 11C15.125 11.9477 14.354 12.7188 13.4062 12.7188C12.4585 12.7188 11.6875 11.9477 11.6875 11C11.6875 10.0523 12.4585 9.28125 13.4062 9.28125Z"
        fill="currentColor"
      />
      <path
        d="M2.57812 3.26562H0.859375C0.384742 3.26562 0 3.65037 0 4.125C0 4.59963 0.384742 4.98438 0.859375 4.98438H2.57812C3.05276 4.98438 3.4375 4.59963 3.4375 4.125C3.4375 3.65037 3.05276 3.26562 2.57812 3.26562Z"
        fill="currentColor"
      />
      <path
        d="M8.42188 7.5625C10.0207 7.5625 11.368 6.46534 11.7506 4.98438H21.1406C21.6153 4.98438 22 4.59963 22 4.125C22 3.65037 21.6153 3.26562 21.1406 3.26562L11.7506 3.26563C11.368 1.78466 10.0207 0.6875 8.42188 0.6875C6.52644 0.6875 4.98438 2.22956 4.98438 4.125C4.98438 6.02044 6.52644 7.5625 8.42188 7.5625ZM6.70312 4.125C6.70312 3.17728 7.47416 2.40625 8.42188 2.40625C9.36959 2.40625 10.1406 3.17728 10.1406 4.125C10.1406 5.07272 9.36959 5.84375 8.42188 5.84375C7.47416 5.84375 6.70312 5.07272 6.70312 4.125Z"
        fill="currentColor"
      />
      <path
        d="M2.57812 18.7344C3.05276 18.7344 3.4375 18.3496 3.4375 17.875C3.4375 17.4004 3.05276 17.0156 2.57812 17.0156H0.859375C0.384742 17.0156 0 17.4004 0 17.875C0 18.3496 0.384742 18.7344 0.859375 18.7344H2.57812Z"
        fill="currentColor"
      />
      <path
        d="M22 17.875C22 17.4004 21.6153 17.0156 21.1406 17.0156H11.7506C11.368 15.5347 10.0207 14.4375 8.42188 14.4375C6.52644 14.4375 4.98438 15.9796 4.98438 17.875C4.98438 19.7704 6.52644 21.3125 8.42188 21.3125C10.0207 21.3125 11.368 20.2153 11.7506 18.7344H21.1406C21.6153 18.7344 22 18.3496 22 17.875V17.875ZM8.42188 19.5938C7.47416 19.5938 6.70312 18.8227 6.70312 17.875C6.70312 16.9273 7.47416 16.1562 8.42188 16.1562C9.36959 16.1562 10.1406 16.9273 10.1406 17.875C10.1406 18.8227 9.36959 19.5938 8.42188 19.5938Z"
        fill="currentColor"
      />
    </svg>
  );
}
