import { ClassicLayoutIcon } from '../icons/classic-layout-icon.component';
import { Close } from '../icons/close.component';
import { LeftAlign } from '../icons/left-align.component';
import { MinimalLayoutIcon } from '../icons/minimal-layout-icon.component';
import { ModernLayoutIcon } from '../icons/modern-layout-icon.component';
import { Moon } from '../icons/moon.component';
import { RetroLayoutIcon } from '../icons/retro-layout-icon.component';
import { RightAlign } from '../icons/right-align.component';
import { Sun } from '../icons/sun.component';

import { useDirection, useThemeColor } from '@graphstax/hooks';
import {
  changeLayoutType,
  LayoutTypeEnum,
  toggleSettingsDrawer,
  useAppDispatch,
  useAppSelector,
} from '@graphstax/redux';
import { useTheme } from '@graphstax/theme-manager';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { useLocalStorage } from 'react-use';
import Button from '../general/button';
import { Dialog } from '../general/dialog.component';
import { RadioGroup } from '../general/radio-group.component';
import Scrollbar from '../general/scrollbar.component';
import { Transition } from '../general/transition.component';

const ColorPreset = [
  {
    label: 'Black',
    value: '#323743',
  },
  {
    label: 'Blue',
    value: '#2a52be',
  },
  {
    label: 'Green',
    value: '#009e60',
  },
  {
    label: 'Red',
    value: '#e34234',
  },
  {
    label: 'Purple',
    value: '#9370DB',
  },
  {
    label: 'Orange',
    value: '#ffa500',
  },
];

// Component: SwitcherButton
interface SwitcherButtonProps {
  checked: boolean;
  title: string;
}
function SwitcherButton({
  checked,
  title,
  children,
}: React.PropsWithChildren<SwitcherButtonProps>) {
  return (
    <div className="group cursor-pointer">
      <span
        className={cn(
          'flex h-[70px] items-center justify-center rounded-lg text-center text-sm font-medium uppercase tracking-wide transition-all',
          checked
            ? 'bg-white shadow-large dark:bg-gray-600'
            : 'bg-gray-100 text-gray-500 group-hover:bg-gray-50 dark:bg-gray-800 dark:text-gray-400 dark:group-hover:bg-gray-700'
        )}
      >
        {children}
      </span>
      <span
        className={cn(
          'mt-3 block text-center text-sm transition-all',
          checked
            ? 'text-brand dark:text-white'
            : 'text-gray-500 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white'
        )}
      >
        {title}
      </span>
    </div>
  );
}

// Component: ThemeSwitcher
function ThemeSwitcher() {
  const { theme, setTheme } = useTheme();

  return (
    <div className="px-6 pt-8">
      <h4 className="mb-4 text-sm font-medium text-gray-900 dark:text-white">
        Mode
      </h4>
      <RadioGroup
        value={theme}
        onChange={setTheme}
        className="grid grid-cols-2 gap-5 "
      >
        <RadioGroup.Option value="light">
          {({ checked }) => (
            <SwitcherButton title={'Light'} checked={checked}>
              <Sun />
            </SwitcherButton>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value="dark">
          {({ checked }) => (
            <SwitcherButton title={'Dark'} checked={checked}>
              <Moon />
            </SwitcherButton>
          )}
        </RadioGroup.Option>
      </RadioGroup>
    </div>
  );
}

// Component: DirectionSwitcher
function DirectionSwitcher() {
  const [direction, setDirection] = useLocalStorage(
    'sr-stitcher-direction',
    'ltr'
  );
  useDirection(direction ? direction : 'ltr');
  return (
    <div className="px-6 pt-8">
      <h4 className="mb-4 text-sm font-medium text-gray-900 dark:text-white">
        Direction
      </h4>
      <RadioGroup
        value={direction}
        onChange={setDirection}
        className="grid grid-cols-2 gap-5 "
      >
        <RadioGroup.Option value="ltr">
          {({ checked }) => (
            <SwitcherButton title={'LTR'} checked={checked}>
              <LeftAlign />
            </SwitcherButton>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value="rtl">
          {({ checked }) => (
            <SwitcherButton title={'RTL'} checked={checked}>
              <RightAlign />
            </SwitcherButton>
          )}
        </RadioGroup.Option>
      </RadioGroup>
    </div>
  );
}

// Component: LayoutSwitcher
const LayoutIcons = {
  [LayoutTypeEnum.MODERN]: <ModernLayoutIcon />,
  [LayoutTypeEnum.MINIMAL]: <MinimalLayoutIcon />,
  [LayoutTypeEnum.RETRO]: <RetroLayoutIcon />,
  [LayoutTypeEnum.CLASSIC]: <ClassicLayoutIcon />,
};

function LayoutSwitcher() {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { pathname } = router;
  const { layoutType } = useAppSelector((state) => state.layout);
  const layoutOptions = Object.values(LayoutTypeEnum);
  function handleLayoutChange(value: LayoutTypeEnum) {
    dispatch(changeLayoutType(value));
    router.push(
      {
        pathname,
        ...(value !== LayoutTypeEnum.MODERN && {
          query: {
            layout: value,
          },
        }),
      },
      undefined,
      { scroll: false }
    );
  }
  return (
    <div className="px-6 pt-8">
      <h4 className="mb-4 text-sm font-medium text-gray-900 dark:text-white">
        Layout
      </h4>
      <RadioGroup
        value={layoutType ?? LayoutTypeEnum.MODERN}
        onChange={(value) => handleLayoutChange(value)}
        className="grid grid-cols-2 gap-5 "
      >
        {layoutOptions.map((option) => (
          <RadioGroup.Option key={option} value={option}>
            {({ checked }) => (
              <SwitcherButton title={option} checked={checked}>
                {LayoutIcons[option]}
              </SwitcherButton>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </div>
  );
}

// Component: ColorSwitcher
function ColorSwitcher() {
  const [themeColor, setThemeColor] = useLocalStorage(
    'criptic-color',
    '#323743'
  );
  useThemeColor(themeColor ? themeColor : '#323743');
  return (
    <div className="px-6 pt-8">
      <h4 className="mb-4 text-sm font-medium text-gray-900 dark:text-white">
        Color
      </h4>
      <RadioGroup
        value={themeColor}
        onChange={setThemeColor}
        className="grid grid-cols-3 gap-5 "
      >
        {ColorPreset.map((item, index) => (
          <RadioGroup.Option value={item.value} key={index}>
            {({ checked }) => (
              <SwitcherButton title={item.label} checked={checked}>
                <span
                  className="h-8 w-8 rounded-full"
                  style={{ backgroundColor: item.value }}
                />
              </SwitcherButton>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </div>
  );
}

export function SettingsDrawer() {
  const dispatch = useAppDispatch();
  const { isSettingsDrawerOpen } = useAppSelector((state) => state.layout);
  return (
    <Transition appear show={isSettingsDrawerOpen || false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-hidden"
        onClose={() => dispatch(toggleSettingsDrawer())}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-0" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-out duration-300"
          enterFrom="ltr:translate-x-full rtl:-translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in duration-300"
          leaveFrom="translate-x-0"
          leaveTo="ltr:translate-x-full rtl:-translate-x-full"
        >
          <div className="fixed inset-y-0 w-80 max-w-full bg-white/95 shadow-[0_0_80px_rgba(17,24,39,0.2)] backdrop-blur ltr:right-0 rtl:left-0 dark:bg-dark/90">
            <div className="h-full w-full">
              <div className="flex h-16 items-center justify-between gap-6 border-b border-dashed border-gray-200 px-6 dark:border-gray-700">
                <h3 className="text-base font-medium uppercase text-gray-900 dark:text-white">
                  Configurator
                </h3>
                <Button
                  title="Close"
                  color="white"
                  shape="circle"
                  variant="transparent"
                  size="small"
                  onClick={() => dispatch(toggleSettingsDrawer())}
                >
                  <Close className="h-auto w-2.5" />
                </Button>
              </div>

              <Scrollbar style={{ height: 'calc(100% - 64px)' }}>
                <div className="pb-8">
                  <ThemeSwitcher />
                  <LayoutSwitcher />
                  <DirectionSwitcher />
                  <ColorSwitcher />
                </div>
              </Scrollbar>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
