import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { MoleculeStructure } from './mol-structure';

import { useRouter } from 'next/router';
import Button from '../general/button';
import { CoinList } from '../general/currency-swap-icons.component';
import Input from '../general/forms/input.component';
import InputLabel from '../general/input-label.component';
import { Listbox } from '../general/listbox.component';
import { Transition } from '../general/transition.component';
import { ChevronDown } from '../icons/chevron-down.component';
import { HistoryIcon } from '../icons/history.component';

interface FarmListTypes {
  from: string;
  to: string;
  earned: string;
  apr: string;
}

const Plot = dynamic(() => import('react-plotly.js'), { ssr: false });

export default function FarmList({
  from,
  to,
  earned,
  apr,
}: React.PropsWithChildren<FarmListTypes>) {
  const [isExpand, setIsExpand] = useState(false);
  const setFrom = from as CoinList;
  const setTo = to as CoinList;

  return (
    <div className="relative mb-3 overflow-hidden rounded-lg bg-white shadow-card transition-all last:mb-0 hover:shadow-large dark:bg-light-dark">
      <div className="relative grid h-auto cursor-pointer grid-cols-2 items-center gap-3 py-4 sm:h-20 sm:grid-cols-3 sm:gap-6 sm:py-0 lg:grid-cols-5">
        <div className="px-4 text-xs font-medium uppercase tracking-wider text-black dark:text-white sm:px-8 sm:text-sm">
          {from}
        </div>
        <div className="px-4 text-xs font-medium uppercase tracking-wider text-black dark:text-white sm:px-8 sm:text-sm">
          {apr}
        </div>
        <div className="hidden px-4 text-xs font-medium uppercase tracking-wider text-black dark:text-white sm:px-8 sm:text-sm lg:block">
          {earned}
        </div>
        <div className="hidden px-4 text-xs font-medium uppercase tracking-wider text-black dark:text-white sm:px-8 sm:text-sm lg:block">
          {to}
        </div>
      </div>
    </div>
  );
}

function getRandomFileSize() {
  // Generate a random decimal number between 0 and 1
  const rand = Math.random();

  // Multiply the random number by 100 to get a file size between 0 and 100 MB
  const fileSize = rand * 100;

  // Round the file size down to the nearest whole number
  return Math.floor(fileSize);
}

// {
//     'tox21':{'step':0, 'fuser':'nf', 'model':'best_model_tox21_0_nf.ckpt'},
//     'bace':{'step':7, 'fuser':'wf', 'model':'best_model_bace_7_wf.ckpt'},
//     'bbbp':{'step':7, 'fuser':'wf', 'model':'best_model_bbbp_7_wf.ckpt'},
//     'clintox':{'step':9, 'fuser':'nf', 'model':'best_model_clintox_9_nf.ckpt'},
//     'hiv':{'step':0, 'fuser':'nf', 'model':'best_model_hiv_0_nf.ckpt'},
//     'toxcast':{'step':0, 'fuser':'nf', 'model':'best_model_toxcast_0_nf.ckpt'}
// }

const BlockchainOptions = [
  {
    id: 1,
    name: 'TOXICITY',
    value: 'toxicity',
    icon: <HistoryIcon />,
  },
  {
    id: 2,
    name: 'BINDING BACE',
    value: 'binding BACE',
    icon: <HistoryIcon />,
  },
  {
    id: 3,
    name: 'BLOOD-BRAIN BARRIER PENETRATION (BBBP)',
    value: 'blood-brain barrier penetration (BBBP)',
    icon: <HistoryIcon />,
  },
  {
    id: 4,
    name: 'INHIBITION OF HIV REPLICATION',
    value: 'inhibition of HIV replication',
    icon: <HistoryIcon />,
  },
];

const cognitoLink =
  'https://permion-saas-api-gateway.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=gtkqs9h8loujab1ptkhlg7dr5&response_type=token&scope=email+openid+phone+profile&redirect_uri=https%3A%2F%2Fmolecule.permion.ai';

const apiGatewayLink =
  'https://xmpn8sb6ec.execute-api.us-east-1.amazonaws.com/development/api';

export function CreateNFT() {
  // const router = useRouter();
  // const { layoutType } = useAppSelector((state) => state.layout);
  const [predictions, changePredictions] =
    useState<Record<string, Record<string, string>[]>>();
  const [rawPredictions, changeRawPredictions] =
    useState<Record<string, string>[]>();
  const [barChartData, changeBarChartData] = useState<any>();
  const [loading, changeIfLoading] = useState(false);
  const [experimentName, changeExperimentName] = useState(
    'CCOc1ccc2nc(S(N)(=O)=O)sc2c1'
  );
  // const [experiments, setExperiments] =
  //   useLocalStorage<any[]>('sr-experiments');
  const [blockchain, setBlockChain] = useState(BlockchainOptions[0]);
  const router = useRouter();

  // const containerRef = useRef(null);

  // useEffect(() => {
  //   if (!containerRef.current) return;

  //   return () => {

  //   };
  // }, [containerRef, experimentName, $3Dmol]);

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    const hashParams = new URLSearchParams(hash);
    const idToken = hashParams.get('access_token');

    if (!idToken) {
      router.replace(cognitoLink);
    }
  }, [router]);

  const triggerPrediction = () => {
    const hash = window.location.hash.slice(1);
    const hashParams = new URLSearchParams(hash);
    const idToken = hashParams.get('access_token');

    const formdata = new FormData();
    formdata.append('smile', experimentName);
    formdata.append('test_options', blockchain.value);

    if (idToken) {
      changeIfLoading(true);
      fetch(apiGatewayLink, {
        method: 'POST',
        body: formdata,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        redirect: 'follow',
      })
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          const groupedPredictions = result.reduce(
            (acc: { [x: string]: any[] }, curr: Record<string, string>) => {
              acc[curr['dataset']] = acc[curr['dataset']] || [];
              acc[curr['dataset']].push(curr);
              return acc;
            },
            {}
          );
          changeRawPredictions(result ?? []);
          changePredictions(groupedPredictions);

          changeBarChartData(
            Object.entries(groupedPredictions).map(([key, value]) => {
              return [
                key,
                (value as any).reduce(
                  (
                    acc: {
                      no: { x: any; y: any }[];
                      yes: { x: any; y: any }[];
                    },
                    curr: { task: any; no: any; yes: any }
                  ) => {
                    acc.no.push({
                      x: curr.task,
                      y: curr.no,
                    });
                    acc.yes.push({
                      x: curr.task,
                      y: curr.yes,
                    });
                    return acc;
                  },
                  {
                    no: [] as Record<string, string>[],
                    yes: [] as Record<string, string>[],
                  }
                ),
              ];
            })
          );

          changeIfLoading(false);
        })
        .catch((error) => {
          console.log('error', error);
          changeIfLoading(false);
        });
    } else {
      router.replace(cognitoLink);
    }
  };

  return (
    <>
      <NextSeo
        title="Autograph"
        description="SR Autograph - Graphstax Product"
      />
      <div className="mx-auto w-full sm:pt-0 lg:px-8 xl:px-10 2xl:px-0">
        <h2 className="mb-6 text-lg font-medium uppercase tracking-wider text-gray-900 dark:text-white sm:mb-10 sm:text-2xl">
          Autograph
        </h2>
        <div className="mb-8 grid grid-cols-1 gap-12 lg:grid-cols-3">
          <div className="lg:col-span-2">
            {/* File uploader */}
            {/* <div className="mb-8">
              <InputLabel title="Upload dataset" important />
              <Uploader />
            </div> */}

            {/* Blockchain */}
            <div className="mb-8">
              <InputLabel title="TASK" important />
              <div className="relative">
                <Listbox
                  value={blockchain}
                  onChange={(e) => {
                    setBlockChain(e);
                    if (predictions) {
                      changePredictions(undefined);
                    }
                  }}
                >
                  <Listbox.Button className="text-case-inherit letter-space-inherit flex h-10 w-full items-center justify-between rounded-lg border border-gray-200 bg-white px-4 text-sm font-medium text-gray-900 outline-none transition-shadow duration-200 hover:border-gray-900 hover:ring-1 hover:ring-gray-900 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-100 dark:hover:border-gray-600 dark:hover:ring-gray-600 sm:h-12 sm:px-5">
                    <div className="flex items-center">
                      <span className="ltr:mr-2 rtl:ml-2">
                        {blockchain.icon}
                      </span>
                      {blockchain.name}
                    </div>
                    <ChevronDown />
                  </Listbox.Button>
                  <Transition
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute left-0 z-10 mt-1 grid w-full origin-top-right gap-0.5 rounded-lg border border-gray-200 bg-white p-1 shadow-large outline-none dark:border-gray-700 dark:bg-gray-800 xs:p-2">
                      {BlockchainOptions.map((option) => (
                        <Listbox.Option key={option.id} value={option}>
                          {({ selected }) => (
                            <div
                              className={`flex cursor-pointer items-center rounded-md px-3 py-2 text-sm text-gray-900 transition dark:text-gray-100  ${
                                selected
                                  ? 'bg-gray-200/70 font-medium dark:bg-gray-600/60'
                                  : 'hover:bg-gray-100 dark:hover:bg-gray-700/70'
                              }`}
                            >
                              <span className="ltr:mr-2 rtl:ml-2">
                                {option.icon}
                              </span>
                              {option.name}
                            </div>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </Listbox>
              </div>
            </div>

            {/* Name */}
            <div className="mb-8">
              <InputLabel title="INPUT" important />
              <Input
                type="text"
                placeholder="FORMULA"
                value={experimentName}
                onChange={(e) => {
                  changeExperimentName(e.currentTarget.value);
                  if (predictions) {
                    changePredictions(undefined);
                  }
                }}
              />
            </div>

            {/* Description */}
            {/* <div className="mb-8">
              <InputLabel
                title="Description"
                subTitle="The description will be included on the experiment's detail page."
              />
              <Textarea placeholder="Provide a detailed description of your experiment" />
            </div> */}

            {/* NFT price type */}
            {/* <div className="flex items-center justify-between gap-4">
              <InputLabel
                title="Put on marketplace"
                subTitle="Enter price to allow users instantly purchase your NFT"
              />
              <div className="shrink-0">
                <Switch checked={publish} onChange={() => setPublish(!publish)}>
                  <div
                    className={cn(
                      publish ? 'bg-brand' : 'bg-gray-200 dark:bg-gray-700',
                      'relative inline-flex h-[22px] w-10 items-center rounded-full transition-colors duration-300'
                    )}
                  >
                    <span
                      className={cn(
                        publish
                          ? 'bg-white ltr:translate-x-5 rtl:-translate-x-5 dark:bg-light-dark'
                          : 'bg-white ltr:translate-x-0.5 rtl:-translate-x-0.5 dark:bg-light-dark',
                        'inline-block h-[18px] w-[18px] transform rounded-full bg-white transition-transform duration-200'
                      )}
                    />
                  </div>
                </Switch>
              </div>
            </div>
            {publish && <PriceType value={priceType} onChange={setPriceType} />} */}
          </div>

          <div className="hidden flex-col lg:flex">
            {/* NFT preview */}
            <InputLabel title="Preview" />
            <div className="relative flex flex-grow flex-col overflow-hidden rounded-lg bg-white shadow-card transition-all duration-200 hover:shadow-large dark:bg-light-dark">
              <div className="flex items-center p-4 text-sm font-medium text-gray-600 transition hover:text-gray-900 dark:text-gray-400">
                {/* <Avatar
                  size="sm"
                  image={AuthorImage}
                  alt="Cameronwilliamson"
                  className="border-white bg-gray-300 ltr:mr-3 rtl:ml-3 dark:bg-gray-400"
                /> */}
                Molecule Rendering
              </div>
              {/* <div className="relative block w-full pb-full"> */}
              {/* <Image
                  src={NFT1}
                  placeholder="blur"
                  layout="fill"
                  objectFit="cover"
                  alt="Pulses of Imagination #214"
                /> */}
              <MoleculeStructure
                id={experimentName}
                structure={experimentName}
                height={250}
                width={250}
                svgMode
              />
              {/* </div> */}
              <div className="p-5">
                <div className="text-sm font-medium text-black dark:text-white">
                  Model
                </div>
                <div className="mt-4 text-lg font-medium text-gray-900 dark:text-white">
                  {blockchain.name}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Price */}
        {/* <div className="mb-8">
          <InputLabel title="Price" important />
          <Input
            min={0}
            type="number"
            placeholder="Enter your price"
            inputClassName="spin-button-hidden"
          />
        </div> */}

        {/* External link */}
        {/* <div className="mb-8">
          <InputLabel
            title="External link"
            subTitle="We will include a link to this URL on this item's detail page, so that users can click to learn more about it."
          />
          <Input type="text" placeholder="https://yoursite.io/item/123" />
        </div> */}

        {/* Unlockable content */}
        {/* <InputLabel title="Traits" />
        <div className="mb-3">
          <ToggleBar
            title="Trait 1"
            subTitle="Description of trait."
            icon={<Unlocked />}
            checked={unlocked}
            onChange={() => setUnlocked(!unlocked)}
          >
            {unlocked && (
              <Textarea placeholder="Enter content (access key, code to redeem, link to a file, etc.)" />
            )}
          </ToggleBar>
        </div> */}

        {/* Explicit content */}
        {/* <div className="mb-8"> */}
        {/* <ToggleBar
            title="Explicit &amp; Sensitive Content"
            subTitle="Set this item as explicit and sensitive content"
            icon={<Warning />}
            checked={explicit}
            onChange={() => setExplicit(!explicit)}
          /> */}
        {/* <ToggleBar
            title="Trait 2"
            subTitle="Description of trait."
            icon={<Unlocked />}
            checked={explicit}
            onChange={() => setExplicit(!explicit)}
          >
            {unlocked && (
              <Textarea placeholder="Enter content (access key, code to redeem, link to a file, etc.)" />
            )}
          </ToggleBar> */}
        {/* </div> */}

        {/* Supply */}
        {/* <div className="mb-8">
          <InputLabel
            title="Supply"
            subTitle="The number of items that can be minted."
          />
          <Input type="number" placeholder="1" disabled />
        </div> */}
        {predictions ? (
          Object.entries(predictions).map(([key, value], i) => (
            <>
              <div className="mb-8">
                <CSVLink data={rawPredictions as Record<string, string>[]}>
                  <Button shape="rounded">Export Results to CSV</Button>
                </CSVLink>
              </div>
              <div className="mx-auto w-full">
                <InputLabel title={key} subTitle="The dataset from model." />
                <div className="mb-3 hidden grid-cols-3 gap-6 rounded-lg bg-white shadow-card dark:bg-light-dark sm:grid lg:grid-cols-5">
                  <span className="px-6 py-6 text-sm tracking-wider text-gray-500 dark:text-gray-300">
                    Dataset
                  </span>
                  <span className="px-6 py-6 text-sm tracking-wider text-gray-500 dark:text-gray-300">
                    No
                  </span>
                  <span className="hidden px-6 py-6 text-sm tracking-wider text-gray-500 dark:text-gray-300 lg:block">
                    Yes
                  </span>
                  <span className="px-6 py-6 text-sm tracking-wider text-gray-500 dark:text-gray-300">
                    Task
                  </span>
                </div>

                {value.slice(0, 10).map((prediction, i) => (
                  <FarmList
                    key={i}
                    from={prediction['dataset']}
                    to={prediction['task']}
                    earned={prediction['no']}
                    apr={prediction['yes']}
                  ></FarmList>
                ))}
              </div>
              <div className="mt-8 mb-8 grid gap-6 sm:my-10 md:grid-cols-2">
                <div className="mt-5 h-64 sm:mt-8 2xl:h-72 3xl:h-[340px] 4xl:h-[480px]">
                  {/* <ResponsiveContainer width="100%" height="100%"> */}
                  <Plot
                    data={[
                      {
                        x: barChartData[i][1]['no'].map(
                          (item: { x: any }) => item.x
                        ),
                        y: barChartData[i][1]['no'].map(
                          (item: { y: any }) => item.y
                        ),
                        type: 'bar',
                        name: 'No',
                      },
                      {
                        x: barChartData[i][1]['yes'].map(
                          (item: { x: any }) => item.x
                        ),
                        y: barChartData[i][1]['yes'].map(
                          (item: { y: any }) => item.y
                        ),
                        type: 'bar',
                        name: 'Yes',
                      },
                    ]}
                    layout={{
                      title: 'Predictions',
                      width: 600,
                      height: 300,
                    }}
                  />
                  {/* </ResponsiveContainer> */}
                </div>
              </div>
            </>
          ))
        ) : (
          <Button
            shape="rounded"
            isLoading={loading}
            onClick={
              () => triggerPrediction()

              // setExperiments([
              //   ...(experiments || []),
              //   {
              //     id: 1,
              //     author: 'sal_anvarov',
              //     authorImage: {
              //       src: 'https://i.pravatar.cc/200',
              //       blurDataURL: 'https://i.pravatar.cc/200',
              //       height: 200,
              //       width: 200,
              //     },
              //     image: {
              //       src: 'https://picsum.photos/200',
              //       blurDataURL: 'https://picsum.photos/200',
              //       height: 200,
              //       width: 200,
              //     },
              //     name: experimentName,
              //     collection: 'no test coverage',
              //     price: getRandomFileSize() + ' MB',
              //   },
              // ]);
              // router.push('/experiment-details');
            }
          >
            PREDICT
          </Button>
        )}
      </div>
    </>
  );
}
