import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { filesize } from 'filesize';

import type {
  IAutoTaskApiResponse,
  IAutoTaskMetadataAPIResponse,
  IAutoTaskTSNEAPIResponse,
  IClassifyTaskApiResponse,
  ICreateAutoTaskPayload,
  ICreateManualTaskPayload,
  IGenerateTracksAPIResponse,
  IManualTaskApiResponse,
} from '../tasks/types';
import type {
  ICreateExperimentPayload,
  IExperimentApiResponse,
  IExperimentEventApiResponse,
  IExperimentOverviewApiResponse,
  IExperimentTaskBasePayload,
} from './types';

export const experimentsApi = createApi({
  reducerPath: 'experimentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/experiments',
  }),
  tagTypes: ['Experiments'],
  endpoints: (builder) => ({
    getExperimentOverview: builder.query<
      {
        label: string;
        value: string;
      }[],
      void
    >({
      query: () => `/stats/overview`,
      transformResponse: (response: IExperimentOverviewApiResponse) =>
        Object.entries(response).map(([label, value]) => ({
          label: label.trim().replace(/^\w/, (c) => c.toUpperCase()),
          value,
        })),
      providesTags: [{ type: 'Experiments', id: 'EXPERIMENTS_OVERVIEW' }],
    }),
    getExperimentEvents: builder.query<IExperimentEventApiResponse[], void>({
      query: () => `/stats/events`,
      providesTags: [{ type: 'Experiments', id: 'EXPERIMENTS_EVENTS' }],
    }),
    getExperiments: builder.query<IExperimentApiResponse[], void>({
      query: () => `/`,
      transformResponse: (response: IExperimentApiResponse[]) =>
        response.map((experiment) => ({
          ...experiment,
          type: experiment.type.trim().replace(/^\w/, (c) => c.toUpperCase()),
          dataset: {
            ...experiment.dataset,
            file_size: filesize(Number(experiment.dataset.file_size), {
              base: 2,
              standard: 'jedec',
            }) as string,
          },
        })),
      providesTags: (experiments) =>
        experiments
          ? [
              ...experiments.map(
                ({ id }) => ({ type: 'Experiments', id } as const)
              ),
              { type: 'Experiments', id: 'LIST' },
            ]
          : [{ type: 'Experiments', id: 'LIST' }],
    }),
    getExperiment: builder.query<IExperimentApiResponse, string>({
      query: (id) => `/${id}`,
      transformResponse: (experiment: IExperimentApiResponse) => ({
        ...experiment,
        dataset: {
          ...experiment.dataset,
          file_size: filesize(Number(experiment.dataset.file_size), {
            base: 2,
            standard: 'jedec',
          }) as string,
        },
      }),
      providesTags: (experiment, error, id) => [{ type: 'Experiments', id }],
    }),
    getExperimentTasks: builder.query<
      (IManualTaskApiResponse | IAutoTaskApiResponse)[],
      string
    >({
      query: (experimentId) => `/${experimentId}/tasks`,
      providesTags: (experiment, error, id) => [{ type: 'Experiments', id }],
    }),
    getExperimentTask: builder.query<
      IManualTaskApiResponse | IAutoTaskApiResponse,
      IExperimentTaskBasePayload
    >({
      query: ({ experimentId, taskId }) => `/${experimentId}/tasks/${taskId}`,
    }),
    createExperiment: builder.mutation<
      IExperimentApiResponse,
      ICreateExperimentPayload
    >({
      query: (body) => ({
        url: `/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        { type: 'Experiments', id: 'LIST' },
        { type: 'Experiments', id: 'EXPERIMENTS_OVERVIEW' },
        { type: 'Experiments', id: 'EXPERIMENTS_EVENTS' },
      ],
    }),
    createManualTaskForExperiment: builder.mutation<
      IManualTaskApiResponse,
      ICreateManualTaskPayload
    >({
      query: ({ experimentId, ...body }) => ({
        url: `/${experimentId}/tasks`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (experimentTask, error, { experimentId }) => [
        { type: 'Experiments', id: 'LIST' },
        { type: 'Experiments', id: experimentId },
      ],
    }),
    createAutoTaskForExperiment: builder.mutation<
      IAutoTaskApiResponse,
      ICreateAutoTaskPayload
    >({
      query: ({ experimentId, ...body }) => ({
        url: `/${experimentId}/tasks`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (experimentTask, error, { experimentId }) => [
        { type: 'Experiments', id: 'LIST' },
        { type: 'Experiments', id: experimentId },
      ],
    }),
    startAutoTaskForExperiment: builder.mutation<
      IAutoTaskApiResponse,
      IExperimentTaskBasePayload
    >({
      query: ({ experimentId, taskId }) => ({
        url: `/${experimentId}/tasks/${taskId}/auto-ml`,
        method: 'POST',
      }),
    }),
    getAutoTaskMetadataForExperiment: builder.query<
      IAutoTaskMetadataAPIResponse,
      IExperimentTaskBasePayload
    >({
      query: ({ experimentId, taskId }) => ({
        url: `/${experimentId}/tasks/${taskId}/auto-ml`,
      }),
    }),
    getAutoTaskTSNEForExperiment: builder.query<
      IAutoTaskTSNEAPIResponse,
      IExperimentTaskBasePayload
    >({
      query: ({ experimentId, taskId }) => ({
        url: `/${experimentId}/tasks/${taskId}/auto-ml/tsne`,
      }),
    }),
    stopAutoMLTaskForExperiment: builder.mutation<
      { message: string },
      IExperimentTaskBasePayload
    >({
      query: ({ experimentId, taskId }) => ({
        url: `/${experimentId}/tasks/${taskId}/auto-ml`,
        method: 'PATCH',
      }),
    }),
    generateTracksForExperimentTask: builder.mutation<
      IGenerateTracksAPIResponse,
      IExperimentTaskBasePayload
    >({
      query: ({ experimentId, taskId }) => ({
        url: `/${experimentId}/tasks/${taskId}/generate-tracks`,
        method: 'POST',
      }),
    }),
    classifyTracksForExperimentTask: builder.mutation<
      IClassifyTaskApiResponse,
      IExperimentTaskBasePayload
    >({
      query: ({ experimentId, taskId }) => ({
        url: `/${experimentId}/tasks/${taskId}/classify-tracks`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetExperimentQuery,
  useGetExperimentsQuery,
  useGetExperimentTaskQuery,
  useGetExperimentTasksQuery,
  useCreateAutoTaskForExperimentMutation,
  useClassifyTracksForExperimentTaskMutation,
  useCreateManualTaskForExperimentMutation,
  useGenerateTracksForExperimentTaskMutation,
  useGetAutoTaskMetadataForExperimentQuery,
  useLazyGetAutoTaskMetadataForExperimentQuery,
  useLazyGetAutoTaskTSNEForExperimentQuery,
  useGetAutoTaskTSNEForExperimentQuery,
  useStartAutoTaskForExperimentMutation,
  useStopAutoMLTaskForExperimentMutation,
  useGetExperimentOverviewQuery,
  useGetExperimentEventsQuery,
  useCreateExperimentMutation,
} = experimentsApi;
