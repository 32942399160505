import AuthorImage from '../assets/author.jpg';
import NFT1 from '../assets/nft/nft-1.jpg';
import NFT2 from '../assets/nft/nft-2.jpg';
import NFT3 from '../assets/nft/nft-3.jpg';
import NFT4 from '../assets/nft/nft-4.jpg';

export const NFTList = [
  {
    id: 1,
    author: 'cameronwilliamson',
    authorImage: AuthorImage,
    image: NFT1,
    name: 'Pulses Of Imagination #214',
    collection: 'Chromory',
    price: '0.40 ETH',
  },
  {
    id: 2,
    author: 'cameronwilliamson',
    authorImage: AuthorImage,
    image: NFT2,
    name: 'Pulses Of Imagination #214',
    collection: 'Chromory',
    price: '0.40 ETH',
  },
  {
    id: 3,
    author: 'cameronwilliamson',
    authorImage: AuthorImage,
    image: NFT3,
    name: 'Imagination of Pulses',
    collection: 'Chromory',
    price: '0.40 ETH',
  },
  {
    id: 4,
    author: 'cameronwilliamson',
    authorImage: AuthorImage,
    image: NFT4,
    name: 'Pulses Of Imagination #214',
    collection: 'Chromory',
    price: '0.40 ETH',
  },
  {
    id: 5,
    author: 'cameronwilliamson',
    authorImage: AuthorImage,
    image: NFT2,
    name: 'Pulses Of Imagination #214',
    collection: 'Chromory',
    price: '0.40 ETH',
  },
  {
    id: 6,
    author: 'cameronwilliamson',
    authorImage: AuthorImage,
    image: NFT4,
    name: 'Pulses Of Imagination #214',
    collection: 'Chromory',
    price: '0.40 ETH',
  },
  {
    id: 7,
    author: 'cameronwilliamson',
    authorImage: AuthorImage,
    image: NFT3,
    name: 'Pulses Of Imagination #214',
    collection: 'Chromory',
    price: '0.40 ETH',
  },
  {
    id: 8,
    author: 'cameronwilliamson',
    authorImage: AuthorImage,
    image: NFT1,
    name: 'Pulses Of Imagination #214',
    collection: 'Chromory',
    price: '0.40 ETH',
  },
];
