import { IExperimentApiResponse } from '../api';

export const getExperiments = async () => {
  const res = await fetch(
    `http://${
      process.env.REACT_API_URL || 'localhost:4200'
    }/api/v1/experiments`,
    {
      redirect: 'follow',
    },
  );
  return (await res.json()) as IExperimentApiResponse[];
};
