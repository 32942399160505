import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import { combineReducers } from 'redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { datasetsApi, experimentsApi, geolocationApi, tasksApi } from './api';
import { authReducer } from './auth';
import { layoutReducer } from './layout';
import { userReducer } from './user';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    experimentsApi.reducerPath,
    datasetsApi.reducerPath,
    tasksApi.reducerPath,
    geolocationApi.reducerPath,
  ],
};

const reducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  user: userReducer,
  [experimentsApi.reducerPath]: experimentsApi.reducer,
  [datasetsApi.reducerPath]: datasetsApi.reducer,
  [tasksApi.reducerPath]: tasksApi.reducer,
  [geolocationApi.reducerPath]: geolocationApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(experimentsApi.middleware)
      .concat(datasetsApi.middleware)
      .concat(tasksApi.middleware)
      .concat(geolocationApi.middleware),
  devTools: true,
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

// top-level state
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
