import {
  changeIsModalOpen,
  LayoutTypeEnum,
  ModalViewType,
  toggleModal,
  useAppDispatch,
  useAppSelector,
} from '@graphstax/redux';
import Followers from '../profile/followers-view';

import cn from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Fragment, useEffect } from 'react';
import Button from '../general/button';
import { Dialog } from '../general/dialog.component';
import { Transition } from '../general/transition.component';
import { Close } from '../icons/close.component';
// dynamic imports
const SearchView = dynamic(() => import('../search/view.component'));
const ShareView = dynamic(() => import('../nft/share-view'));
const ProfileInfo = dynamic(() => import('../profile/profile-info-view'));
const PreviewContent = dynamic(
  () => import('../create-nft/nft-preview-content.component')
);

function renderModalContent(view: ModalViewType | string) {
  switch (view) {
    case 'SEARCH_VIEW':
      return <SearchView />;
    case 'SHARE_VIEW':
      return <ShareView />;
    case 'PROFILE_INFO_VIEW':
      return <ProfileInfo />;
    case 'FOLLOWING_VIEW':
      return <Followers />;
    case 'FOLLOWERS_VIEW':
      return <Followers />;
    case 'NFT_PREVIEW':
      return <PreviewContent />;
    default:
      return null;
  }
}

export function ModalContainer() {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { layoutType, isModalOpen, modalViewType } = useAppSelector(
    (state) => state.layout
  );

  const closeModal = () => dispatch(changeIsModalOpen(false));

  useEffect(() => {
    // close search modal when route change
    router.events.on('routeChangeStart', closeModal);
    return () => {
      router.events.off('routeChangeStart', closeModal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Transition appear show={isModalOpen || false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 h-full w-full overflow-y-auto overflow-x-hidden p-4 text-center sm:p-6 lg:p-8 xl:p-10 3xl:p-12"
        onClose={() => dispatch(toggleModal())}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 z-40 cursor-pointer bg-gray-700 bg-opacity-60 backdrop-blur" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        {modalViewType && modalViewType !== 'SEARCH_VIEW' && (
          <span className="inline-block h-full align-middle" aria-hidden="true">
            &#8203;
          </span>
        )}

        {/* This element is need to fix FocusTap headless-ui warning issue */}
        <div className="sr-only">
          <Button
            size="small"
            color="gray"
            shape="circle"
            onClick={() => dispatch(toggleModal())}
            className="opacity-50 hover:opacity-80 "
          >
            <Close className="h-auto w-[13px]" />
          </Button>
        </div>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-105"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-105"
        >
          <div
            className={cn(
              'relative z-50 inline-block w-full text-left align-middle',
              layoutType === LayoutTypeEnum.RETRO ? 'sm:w-auto' : 'xs:w-auto'
            )}
          >
            {modalViewType && renderModalContent(modalViewType)}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
