import { useAppSelector } from '@graphstax/redux';
import cn from 'classnames';
import { StaticImageData } from 'next/image';
import NFTGrid from '../general/nft-card.component';

export type ExperimentEntryType = {
  id: number;
  author: string;
  authorImage: StaticImageData;
  image: StaticImageData;
  name: string;
  collection: string;
  price: string;
};

export default function Feeds({
  className,
  experiments,
}: {
  className?: string;
  experiments: ExperimentEntryType[];
}) {
  const { isGridCompact } = useAppSelector((state) => state.layout);

  return (
    <div
      className={cn(
        'grid gap-5 sm:grid-cols-2 md:grid-cols-3',
        isGridCompact
          ? '3xl:!grid-cols-4 4xl:!grid-cols-5'
          : '3xl:!grid-cols-3 4xl:!grid-cols-4',
        className
      )}
    >
      {experiments.map((nft) => (
        <NFTGrid
          key={nft.id}
          name={nft.name}
          image={nft.image}
          author={nft.author}
          authorImage={nft.authorImage}
          price={nft.price}
          collection={nft.collection}
        />
      ))}
    </div>
  );
}
