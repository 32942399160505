import { persistor, store } from '@graphstax/redux';
import { ThemeProvider } from '@graphstax/theme-manager';
import {
  DrawersContainer,
  FallbackLoader,
  ModalContainer,
  SettingsButton,
  SettingsDrawer,
} from '@graphstax/ui';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import 'overlayscrollbars/css/OverlayScrollbars.css';
import { NextPageWithLayout } from '../types';
import './custom-styles.css';
import './styles.css';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 maximum-scale=1"
        />
        <title>Welcome to sr-component-stitcher!</title>
      </Head>

      <Provider {...{ store }}>
        <PersistGate loading={<FallbackLoader />} {...{ persistor }}>
          <ThemeProvider
            attribute="class"
            enableSystem={false}
            defaultTheme="light"
          >
            <main>
              {getLayout(<Component {...pageProps} />)}
              <SettingsButton />
              <SettingsDrawer />
              <ModalContainer />
              <DrawersContainer />
            </main>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default CustomApp;
