import Avatar1 from '../assets/avatar/1.png';
import Avatar2 from '../assets/avatar/2.png';
import Avatar3 from '../assets/avatar/3.png';
import Avatar4 from '../assets/avatar/4.png';
import Avatar5 from '../assets/avatar/5.png';
import Avatar6 from '../assets/avatar/6.png';
import Bitcoin from '../assets/currency/bitcoin.svg';
import Ethereum from '../assets/currency/ethereum.svg';

export const nftData = {
  isAuction: true,
  name: 'Experiment Details',
  image: {
    src: 'https://picsum.photos/800',
    height: 800,
    width: 800,
  },
  minted_date: new Date().toLocaleDateString(),
  minted_slug: '/explore-experiments',
  price: 0.2,
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas porta, felis vel maximus porta, quam leo lobortis ex, ut mattis mi ipsum quis est. Praesent ac orci sem. Mauris volutpat volutpat mollis. Aenean nec ultricies arcu. In et ex quis augue rhoncus ultricies. Ut luctus ligula tortor, ac blandit elit pellentesque pretium. Ut molestie dui tortor.',
  creator: { id: 1, logo: Avatar1, name: 'Sal Anvarov', slug: '#' },
  collection: {
    id: 1,
    logo: {
      src: 'http://via.placeholder.com/100',
      height: 100,
      width: 100,
    },
    name: 'Untested',
    slug: '#',
  },
  owner: { id: 1, logo: Avatar4, name: '@williamson', slug: '#' },
  block_chains: [
    { id: 1, logo: Bitcoin, name: 'Ethereum', slug: '#' },
    { id: 2, logo: Ethereum, name: 'Bitcoin', slug: '#' },
  ],
  bids: [
    {
      id: 1,
      label: 'Bid Placed',
      name: 'ronson',
      authorSlug: '#',
      created_at: '2022-01-22T17:26:22.000000Z',
      avatar: Avatar1,
      amount: 0.02,
      transactionUrl: '#',
    },
    {
      id: 2,
      label: 'Bid Placed',
      name: 'Cameron',
      authorSlug: '#',
      created_at: '2022-02-22T17:26:22.000000Z',
      avatar: Avatar2,
      amount: 0.05,
      transactionUrl: '#',
    },
    {
      id: 3,
      label: 'Bid Placed',
      name: 'Williamson',
      authorSlug: '#',
      created_at: '2022-03-22T17:26:22.000000Z',
      avatar: Avatar3,
      amount: 0.07,
      transactionUrl: '#',
    },
    {
      id: 4,
      label: 'Bid Placed',
      name: 'ronson',
      authorSlug: '#',
      created_at: '2022-01-22T17:26:22.000000Z',
      avatar: Avatar4,
      amount: 0.78,
      transactionUrl: '#',
    },
    {
      id: 5,
      label: 'Bid Placed',
      name: 'Cameron',
      authorSlug: '#',
      created_at: '2022-02-22T17:26:22.000000Z',
      avatar: Avatar5,
      amount: 0.98,
      transactionUrl: '#',
    },
    {
      id: 6,
      label: 'File Size',
      name: 'Sal Anvarov',
      authorSlug: '#',
      created_at: '2022-03-22T17:26:22.000000Z',
      avatar: Avatar6,
      amount: 5.01,
      transactionUrl: '#',
    },
  ],
  history: [
    {
      id: 1,
      label: 'Minted',
      name: 'Williamson',
      authorSlug: '#',
      created_at: '2022-03-22T17:26:22.000000Z',
      avatar: Avatar3,
      amount: null,
      transactionUrl: '#',
    },
    {
      id: 2,
      label: 'Listed',
      name: 'Cameron',
      authorSlug: '#',
      created_at: '2022-02-22T17:26:22.000000Z',
      avatar: Avatar2,
      amount: null,
      transactionUrl: '#',
    },
    {
      id: 3,
      label: 'Bid Placed',
      name: 'ronson',
      authorSlug: '#',
      created_at: '2022-01-22T17:26:22.000000Z',
      avatar: Avatar1,
      amount: 1.01,
      transactionUrl: '#',
    },
  ],
};
