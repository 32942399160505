import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DrawerLayoutType,
  ILayoutState,
  LayoutTypeEnum,
  ModalViewType,
} from './types';

const initialState: ILayoutState = {
  layoutType: LayoutTypeEnum.MODERN,
  isDrawerOpen: false,
  isSettingsDrawerOpen: false,
  drawerLayout: 'DASHBOARD_SIDEBAR',
  modalViewType: 'SEARCH_VIEW',
  modalMetadata: undefined,
  isModalOpen: false,
  isGridCompact: false,
};

export const layoutSlice = createSlice({
  name: '@@layout',
  initialState,
  reducers: {
    changeLayoutType: (state, action: PayloadAction<LayoutTypeEnum>) => {
      state.layoutType = action.payload;
    },
    changeDrawerLayout: (state, action: PayloadAction<DrawerLayoutType>) => {
      state.drawerLayout = action.payload;
    },
    toggleDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    toggleSettingsDrawer: (state) => {
      state.isSettingsDrawerOpen = !state.isSettingsDrawerOpen;
    },
    toggleModal: (state) => {
      state.isModalOpen = !state.isModalOpen;
    },
    changeIsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isDrawerOpen = action.payload;
    },
    changeIsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
    changeModalViewType: (state, action: PayloadAction<ModalViewType>) => {
      state.modalViewType = action.payload;
    },
    changeModalMetadata: (
      state,
      action: PayloadAction<Record<string, unknown>>
    ) => {
      state.modalMetadata = action.payload;
    },
    changeIsGridCompact: (state, action: PayloadAction<boolean>) => {
      state.isGridCompact = action.payload;
    },
    toggleIsGridCompact: (state) => {
      state.isGridCompact = !state.isGridCompact;
    },
  },
});

export const {
  changeLayoutType,
  changeDrawerLayout,
  toggleDrawer,
  toggleSettingsDrawer,
  toggleModal,
  changeIsModalOpen,
  changeModalViewType,
  changeIsDrawerOpen,
  changeIsGridCompact,
  changeModalMetadata,
  toggleIsGridCompact,
} = layoutSlice.actions;

export const layoutReducer = layoutSlice.reducer;
