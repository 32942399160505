import { useIsMounted } from '@graphstax/hooks';
import { LayoutTypeEnum, useAppSelector } from '@graphstax/redux';
import dynamic from 'next/dynamic';
import Loader from '../general/loader.component';
// dynamic imports
const MinimalLayout = dynamic(() => import('./_minimal.component'), {
  loading: () => <FallbackLoader />,
});
const ClassicLayout = dynamic(() => import('./_classic.component'), {
  loading: () => <FallbackLoader />,
});
const RetroLayout = dynamic(() => import('./_retro.component'), {
  loading: () => <FallbackLoader />,
});
const ModernLayout = dynamic(() => import('./_modern.component'), {
  loading: () => <FallbackLoader />,
});

export function FallbackLoader() {
  return (
    <div className="fixed z-50 grid h-full w-full place-content-center">
      <Loader variant="blink" />
    </div>
  );
}

export function RootLayout({
  children,
  contentClassName,
}: React.PropsWithChildren<{ contentClassName?: string }>) {
  const isMounted = useIsMounted();
  const { layoutType } = useAppSelector((state) => state.layout);

  // fix the `Hydration failed because the initial UI does not match` issue
  if (!isMounted) return null;

  // render minimal layout
  if (layoutType === LayoutTypeEnum.MINIMAL) {
    return <MinimalLayout>{children}</MinimalLayout>;
  }

  // render retro layout
  if (layoutType === LayoutTypeEnum.RETRO) {
    return (
      <RetroLayout contentClassName={contentClassName}>{children}</RetroLayout>
    );
  }

  // render classic layout
  if (layoutType === LayoutTypeEnum.CLASSIC) {
    return (
      <ClassicLayout contentClassName={contentClassName}>
        {children}
      </ClassicLayout>
    );
  }

  // render default layout which is modern
  return (
    <ModernLayout contentClassName={contentClassName}>{children}</ModernLayout>
  );
}
