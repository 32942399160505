import { toggleModal } from '@graphstax/redux';
import { useDispatch } from 'react-redux';
import Button from '../general/button';

export default function Preview() {
  const dispatch = useDispatch();

  return (
    <div className="flex items-center justify-between">
      <Button
        size="small"
        variant="ghost"
        onClick={() => dispatch(toggleModal())}
        className="dark:border-white dark:text-white"
        type="button"
      >
        PREVIEW
      </Button>
    </div>
  );
}
