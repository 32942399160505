import Image from 'next/image';

export const TopTokensData = [
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    code: 'BTC',
    name: 'Bitcoin',
    volume: '256.5M',
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    code: 'ETH',
    name: 'Ethereum',
    volume: '740.7M',
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    code: 'USDT',
    name: 'Tether USD',
    volume: '566.2M',
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    code: 'BNB',
    name: 'Binance Coin',
    volume: '396.4M',
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    code: 'USDC',
    name: 'USD Coin',
    volume: '145.1M',
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    code: 'ADA',
    name: 'Cardano',
    volume: '267.3M',
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    code: 'DOGE',
    name: 'Doge Coin',
    volume: '421.9M',
  },
];

export const TopPoolsData = [
  {
    from: 'BTC',
    to: 'USDC',
    volume: '434.2M',
  },
  {
    from: 'USDT',
    to: 'DOGE',
    volume: '356.4M',
  },
  {
    from: 'ADA',
    to: 'BNB',
    volume: '295.6M',
  },
  {
    from: 'USDT',
    to: 'USDC',
    volume: '402.5M',
  },
  {
    from: 'ETH',
    to: 'DOGE',
    volume: '590.2M',
  },
  {
    from: 'BTC',
    to: 'ADA',
    volume: '104.6M',
  },
];
