export const Verified: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M5.0568 7.05557L6.40109 8.41232L9.08969 5.69883M4.27375 2.10141C4.75588 2.06258 5.2136 1.87124 5.58175 1.55464C5.99775 1.19665 6.52655 1 7.07324 1C7.61994 1 8.14874 1.19665 8.56474 1.55464C8.93289 1.87124 9.3906 2.06258 9.87274 2.10141C10.4177 2.14517 10.9293 2.3835 11.3159 2.77363C11.7024 3.16376 11.9386 3.68016 11.9819 4.23015C12.0204 4.71675 12.21 5.1787 12.5237 5.55026C12.8784 5.97011 13.0732 6.50381 13.0732 7.05557C13.0732 7.60733 12.8784 8.14103 12.5237 8.56088C12.21 8.93244 12.0204 9.3944 11.9819 9.881C11.9386 10.431 11.7024 10.9474 11.3159 11.3375C10.9293 11.7276 10.4177 11.966 9.87274 12.0097C9.3906 12.0486 8.93289 12.2399 8.56474 12.5565C8.14874 12.9145 7.61994 13.1111 7.07324 13.1111C6.52655 13.1111 5.99775 12.9145 5.58175 12.5565C5.2136 12.2399 4.75588 12.0486 4.27375 12.0097C3.7288 11.966 3.21715 11.7276 2.83059 11.3375C2.44404 10.9474 2.2079 10.431 2.16455 9.881C2.12607 9.3944 1.93649 8.93244 1.6228 8.56088C1.26808 8.14103 1.07324 7.60733 1.07324 7.05557C1.07324 6.50381 1.26808 5.97011 1.6228 5.55026C1.93649 5.1787 2.12607 4.71675 2.16455 4.23015C2.2079 3.68016 2.44404 3.16376 2.83059 2.77363C3.21715 2.3835 3.7288 2.14517 4.27375 2.10141Z"
        stroke="CurrentColor"
      />
    </svg>
  );
};
