import Image from 'next/image';

export const walletCurrencies = [
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    name: 'Bitcoin',
    code: 'BTC',
    volume: '+12.5%',
    color: '#F79517',
    isChangePositive: true,
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    name: 'Tether USD',
    code: 'USDT',
    volume: '-8.47%',
    color: '#259C77',
    isChangePositive: false,
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    name: 'Cardano',
    code: 'ADA',
    volume: '+5.63%',
    color: '#3468D1',
    isChangePositive: true,
  },
  {
    icon: (
      <Image
        src={{
          src: 'https://picsum.photos/200',
          height: 200,
          width: 200,
        }}
        alt="placeholder"
      />
    ),
    name: 'Binance Coin',
    code: 'BNB',
    volume: '-3.02%',
    color: '#F3BA2F',
    isChangePositive: false,
  },
];
