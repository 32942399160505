export enum LayoutTypeEnum {
  MODERN = 'Modern',
  MINIMAL = 'Minimal',
  RETRO = 'Retro',
  CLASSIC = 'Classic',
}

export type DrawerLayoutType =
  | 'DASHBOARD_SIDEBAR'
  | 'DRAWER_MENU'
  | 'DRAWER_SEARCH'
  | 'DRAWER_FILTER';

export type ModalViewType =
  | 'SEARCH_VIEW'
  | 'SHARE_VIEW'
  | 'WALLET_CONNECT_VIEW'
  | 'PROFILE_INFO_VIEW'
  | 'FOLLOWING_VIEW'
  | 'FOLLOWERS_VIEW';

export interface ILayoutState {
  readonly layoutType: LayoutTypeEnum;
  readonly isDrawerOpen: boolean;
  readonly isSettingsDrawerOpen: boolean;
  readonly drawerLayout: DrawerLayoutType;
  readonly isModalOpen: boolean;
  readonly modalMetadata?: Record<string, unknown>;
  readonly modalViewType: ModalViewType;
  readonly isGridCompact: boolean;
}
