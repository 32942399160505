export function Unlocked(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.56963 12.7768C5.56963 12.0055 6.19493 11.3802 6.96628 11.3802C7.73763 11.3802 8.36293 12.0055 8.36293 12.7768C8.36293 13.5482 7.73763 14.1735 6.96628 14.1735C6.19493 14.1735 5.56963 13.5482 5.56963 12.7768Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.92763 1.60825C5.14398 1.52211 5.36968 1.46163 5.60011 1.42806C6.97187 1.22818 8.30023 2.00084 8.80469 3.29203L8.87454 3.47082C8.9611 3.69236 9.02284 3.92281 9.05866 4.15794L9.26035 5.48226L10.6411 5.27197L10.4394 3.94765C10.388 3.61056 10.2995 3.28018 10.1754 2.96258L10.1056 2.78378C9.36464 0.887291 7.41355 -0.247573 5.39873 0.0460034C5.06028 0.0953196 4.72877 0.184147 4.411 0.310666C2.51933 1.06383 1.39707 3.02219 1.70364 5.03508L1.73254 5.22484C1.78388 5.56194 1.87241 5.89231 1.99649 6.20992L2.60557 7.76888L2.25038 7.79724C1.24225 7.87772 0.419059 8.63554 0.255637 9.63359C-0.0852123 11.7152 -0.0852123 13.8384 0.255637 15.92C0.419059 16.9181 1.24225 17.6759 2.25038 17.7564L3.64342 17.8676C5.85514 18.0441 8.07742 18.0441 10.2891 17.8676L11.6822 17.7564C12.6903 17.6759 13.5135 16.9181 13.6769 15.92C14.0178 13.8384 14.0178 11.7152 13.6769 9.63359C13.5135 8.63554 12.6903 7.87772 11.6822 7.79724L10.2891 7.68603C8.21639 7.52056 6.13438 7.51016 4.06047 7.65484L3.29738 5.70167C3.21083 5.48013 3.14908 5.24969 3.11327 5.01455L3.08437 4.82479C2.87564 3.45435 3.63971 2.12103 4.92763 1.60825ZM10.178 9.07825C8.04025 8.90759 5.89231 8.90759 3.75457 9.07825L2.36152 9.18946C1.9938 9.21881 1.69354 9.49523 1.63393 9.85927C1.31755 11.7914 1.31755 13.7622 1.63393 15.6943C1.69354 16.0584 1.9938 16.3348 2.36152 16.3641L3.75457 16.4754C5.8923 16.646 8.04025 16.646 10.178 16.4754L11.571 16.3641C11.9388 16.3348 12.239 16.0584 12.2986 15.6943C12.615 13.7622 12.615 11.7914 12.2986 9.85927C12.239 9.49523 11.9388 9.21881 11.571 9.18946L10.178 9.07825Z"
        fill="currentColor"
      />
    </svg>
  );
}
