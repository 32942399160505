import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IGeolocationAPIResponse } from './types';

export const geolocationApi = createApi({
  reducerPath: 'geolocationApi',
  tagTypes: ['Geolocation'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'http://ip-api.com/json',
  }),
  endpoints: (builder) => ({
    getGeolocation: builder.query<IGeolocationAPIResponse, void>({
      query: () => `/`,
      providesTags: [{ type: 'Geolocation', id: 'GEOLOCATION' }],
    }),
  }),
});

export const { useGetGeolocationQuery } = geolocationApi;
