export function LoopIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="34"
      height="16"
      viewBox="0 0 34 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4371 4.17509L14.2709 4.36186L14.4371 4.17509C13.1745 3.05165 12.0077 2.1981 10.9443 1.62437C9.88249 1.05147 8.90984 0.75 8.04 0.75C4.02007 0.75 0.75 4.02007 0.75 8.04C0.75 12.0599 4.02007 15.33 8.04 15.33C8.90984 15.33 9.88249 15.0285 10.9443 14.4556C12.0077 13.8819 13.1745 13.0283 14.4371 11.9049C15.5089 10.9513 16.4194 10.0073 17 9.37695C17.5806 10.0073 18.4911 10.9513 19.5629 11.9049C20.8255 13.0283 21.9923 13.8819 23.0557 14.4556C24.1175 15.0285 25.0902 15.33 25.96 15.33C29.9799 15.33 33.25 12.0599 33.25 8.04C33.25 4.02007 29.9799 0.75 25.96 0.75C25.0902 0.75 24.1175 1.05147 23.0557 1.62437C21.9923 2.1981 20.8255 3.05165 19.5629 4.17509C18.4911 5.12874 17.5806 6.07275 17 6.70311C16.4194 6.07279 15.5089 5.12879 14.4371 4.17509ZM8.04 13.55C5.00201 13.55 2.53 11.078 2.53 8.04C2.53 5.00201 5.00201 2.53 8.04 2.53C8.59623 2.53 9.2609 2.74866 9.98771 3.1305C10.7103 3.51015 11.4726 4.03936 12.2181 4.63336C13.6248 5.75414 14.9525 7.0898 15.8148 8.04017C14.9529 8.99058 13.6258 10.3261 12.2193 11.4468C11.4739 12.0408 10.7117 12.5699 9.98891 12.9495C9.26195 13.3313 8.5969 13.55 8.04 13.55ZM25.96 13.55C25.4038 13.55 24.7391 13.3313 24.0123 12.9495C23.2897 12.5699 22.5274 12.0406 21.7819 11.4466C20.3752 10.3259 19.0475 8.9902 18.1852 8.03982C19.0471 7.08942 20.3742 5.75386 21.7806 4.63318C22.5261 4.03923 23.2883 3.51006 24.0111 3.13046C24.738 2.74867 25.4031 2.53 25.96 2.53C28.998 2.53 31.47 5.00201 31.47 8.04C31.47 11.078 28.998 13.55 25.96 13.55Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  );
}
