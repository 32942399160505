import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetUser, setUser } from '../user';
import type { IAuthState, ILoginPayload } from './types';

const initialState: IAuthState = {
  isAuthenticated: undefined,
  // status is for async thunk operations only (maybe requires a renaming to be clearer)
  loading: undefined,
  error: undefined,
};

// async methods for auth logic
export const login = createAsyncThunk(
  '@@auth/login',
  async (
    { name, occupation }: ILoginPayload,
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(
        setUser({
          name,
          occupation,
          profilePicture: `https://ui-avatars.com/api/?name=${encodeURIComponent(
            name
          )}&color=8181a5&background=e9e9f1`,
        })
      );
      return true;
    } catch (e) {
      if (e instanceof Error) {
        return rejectWithValue(e.message as string);
      }
      return rejectWithValue('Something went wrong');
    }
  }
);

export const logout = createAsyncThunk(
  '@@auth/logout',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      // reset the user state
      dispatch(resetUser());
      return false;
    } catch (e) {
      if (e instanceof Error) {
        return rejectWithValue(e.message as string);
      }
      return rejectWithValue('Something went wrong');
    }
  }
);

export const authSlice = createSlice({
  name: '@@auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(logout.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = action.payload as boolean;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.isAuthenticated = action.payload as boolean;
    });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = authSlice.actions;

export const authReducer = authSlice.reducer;
