import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  IDatasetApiResponse,
  IDatasetFilterOrTransformerAPIResponse,
} from './types';

export const datasetsApi = createApi({
  reducerPath: 'datasetsApi',
  tagTypes: ['Datasets'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/datasets',
  }),
  endpoints: (builder) => ({
    getDatasets: builder.query<IDatasetApiResponse[], void>({
      query: () => `/`,
      providesTags: (datasets) =>
        datasets
          ? [
              ...datasets.map(({ id }) => ({ type: 'Datasets' as const, id })),
              { type: 'Datasets', id: 'DATASET_LIST' },
            ]
          : [{ type: 'Datasets', id: 'DATASET_LIST' }],
    }),
    getDatasetFilters: builder.query<
      IDatasetFilterOrTransformerAPIResponse[],
      void
    >({
      query: () => `/filters`,
      providesTags: (filters) =>
        filters
          ? [
              ...filters.map(({ id }) => ({ type: 'Datasets' as const, id })),
              { type: 'Datasets', id: 'DATASET_FILTERS_LIST' },
            ]
          : [{ type: 'Datasets', id: 'DATASET_FILTERS_LIST' }],
    }),
    getDatasetTransformers: builder.query<
      IDatasetFilterOrTransformerAPIResponse[],
      void
    >({
      query: () => `/transformers`,
      providesTags: (transformers) =>
        transformers
          ? [
              ...transformers.map(({ id }) => ({
                type: 'Datasets' as const,
                id,
              })),
              { type: 'Datasets', id: 'DATASET_TRANSFORMERS_LIST' },
            ]
          : [{ type: 'Datasets', id: 'DATASET_TRANSFORMERS_LIST' }],
    }),
  }),
});

export const {
  useGetDatasetsQuery,
  useGetDatasetFiltersQuery,
  useGetDatasetTransformersQuery,
} = datasetsApi;
