export enum ExperimentTypesEnum {
  MANUAL = 'Manual',
  AUTOMATIC = 'Auto',
}

export interface IExperimentTaskBasePayload {
  experimentId: string;
  taskId: string;
}

export interface IExperimentApiResponse {
  id: string;
  name: string;
  dataset: IDataset;
  description: string;
  type: string;
  created_at: Date;
  last_modified: Date;
  tasks: string[];
}
// Overview for experiments
export interface IExperimentOverviewApiResponse {
  completed: number;
  total: number;
  pending: number;
}

// Notification events for experiments
export interface IExperimentEventApiResponse {
  message: string;
  meta: Record<string, string>;
  created_at: Date;
}

export interface ICreateExperimentPayload {
  name: string;
  description: string;
  user_comment?: string;
  type: 'manual' | 'auto';
  dataset: IDataset;
}

export interface IDataset {
  id: string;
  filters: string[];
  transformers: string[];
  file_size: number | string;
}
