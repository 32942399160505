import {
  changeIsDrawerOpen,
  DrawerLayoutType,
  useAppDispatch,
  useAppSelector,
} from '@graphstax/redux';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Fragment, useEffect } from 'react';
import { Dialog } from '../general/dialog.component';
import { Transition } from '../general/transition.component';
// dynamic imports
const Sidebar = dynamic(() => import('../layout/sidebar/_default.component'));
const DrawerFilters = dynamic(() => import('../search/filters.component'));
const DrawerMenu = dynamic(
  () => import('../layout/sidebar/_layout-menu.component')
);
const PreviewContent = dynamic(
  () => import('../create-nft/nft-preview-content.component')
);

function renderDrawerContent(view: DrawerLayoutType | string) {
  switch (view) {
    case 'DASHBOARD_SIDEBAR':
      return <Sidebar />;
    case 'DRAWER_SEARCH':
      return <DrawerFilters />;
    case 'DRAWER_PREVIEW_NFT':
      return <PreviewContent />;
    default:
      return <DrawerMenu />;
  }
}

export function DrawersContainer() {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { isDrawerOpen, drawerLayout } = useAppSelector(
    (state) => state.layout
  );

  const closeDrawer = () => dispatch(changeIsDrawerOpen(false));
  useEffect(() => {
    // close search modal when route change
    router.events.on('routeChangeStart', closeDrawer);
    return () => {
      router.events.off('routeChangeStart', closeDrawer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Transition appear show={isDrawerOpen ?? false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-hidden"
        onClose={closeDrawer}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-60 backdrop-blur" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-out duration-300"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in duration-300"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="fixed inset-y-0 left-0 flex w-full max-w-full xs:w-auto">
            {drawerLayout && renderDrawerContent(drawerLayout)}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
